.c-section {
  &--contact {
    position: relative;
    z-index: 0;
    margin-top: -24px;
    box-shadow: $shadow-dark;

    @include media-breakpoint-up(lg) {
      margin-top: -116px;
      padding: 56px !important;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
    }

    &:before {
      top: -1px;
      width: 100px;
      height: 50px;
      background: url(../assets/images/bgTopLeft.svg) center center;
      background-repeat: no-repeat;
      background-size: contain;

      @include media-breakpoint-up(md) {
        width: 200px;
        height: 100px;
      }

      @include media-breakpoint-up(lg) {
        top: 0;
        width: 255px;
        height: 126px;
      }
    }

    &:after {
      bottom: 0;
      right: 0;
      width: 67px;
      height: 59px;
      background: url(../assets/images/bgBottomRight.svg) center center;
      background-repeat: no-repeat;
      background-size: cover;

      @include media-breakpoint-up(md) {
        width: 134px;
        height: 117px;
      }

      @include media-breakpoint-up(lg) {
        width: 223px;
        height: 194px;
      }
    }

    h1,
    p {
      font-family: $font-family-soft;
    }

    h1 {
      &.ci--question-mark,
      &.ci--tick,
      &.ci--calendar-1,
      &.ci--calendar-2,
      &.ci--calendar-3,
      &.ci--chat,
      &.ci--payment,
      &.ci--pause,
      &.ci--calendar-slash,
      &.ci--calendar-change {
        &:before {
          left: 50%;
          transform: translateX(-50%);
          top: -40px;
        }
      }

      @include media-breakpoint-up(lg) {
        margin-top: 150px !important;

        &.ci--question-mark,
        &.ci--tick,
        &.ci--calendar-1,
        &.ci--calendar-2,
        &.ci--calendar-3,
        &.ci--chat,
        &.ci--payment,
        &.ci--pause,
        &.ci--calendar-slash,
        &.ci--calendar-change {
          &:before {
            top: -120px;
          }
        }
      }
    }

    p {
      font-size: $font-size-medium;
      color: map-get($colors, 'gray-300');

      @include media-breakpoint-up(lg) {
        max-width: 569px;
        font-size: $font-size-larger;
      }

      &.msg--error {
        color: theme-color('warning');
      }
    }

    .inner {
      position: relative;
      z-index: 0;
      @include media-breakpoint-up(lg) {
        padding: 66px 72px !important;
      }

      h3 {
        @include media-breakpoint-down(md) {
          font-size: 1rem;
        }
      }

      form {
        max-width: 800px;

        textarea {
          max-width: 600px;
          border-color: map-get($colors, 'gray-300');
        }

        textarea::placeholder {
          font-family: $font-family-soft;
          color: map-get($colors, 'foird');
        }

        button {
          min-width: 236px;
          font-family: $font-family-soft;
          font-size: $font-size-medium;
          line-height: 18px;
        }
      }
    }

    .mail {
      width: 197px;
      height: 161px;
      background: url(../assets/images/icon-mail.svg) center center;
      background-repeat: no-repeat;
      background-size: contain;

      @include media-breakpoint-up(lg) {
        width: 328px;
        height: 268px;
      }
    }

    .contact-notice {
      @include media-breakpoint-up(lg) {
        max-width: 740px;
      }

      &__type,
      &__msg {
        font-family: $font-family-soft;
      }

      &__msg {
        @include media-breakpoint-up(md) {
          text-align: left;
        }
      }

      &--reminder {
        background-color: $lily-white;
        .contact-notice__type,
        .contact-notice__msg {
          color: $water-blue;
        }
      }

      &--warning {
        background-color: #fff2ed;

        .contact-notice__type,
        .contact-notice__msg {
          color: $punch;
        }

        .contact-notice__type {
          border-color: $punch !important;
          white-space: nowrap;
        }

        .contact-notice__msg a {
          color: $punch;
        }
      }
    }

    .fw-semi-bold {
      font-weight: $font-weight-medium;
    }
  }
}

.list-contact-options {
  .list-group-item {
    &:hover {
      background-color: map-get($colors, 'lily-white');
    }

    &:last-of-type {
      margin-bottom: 0 !important;
    }

    &.ci {
      &:before {
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        @include media-breakpoint-up(lg) {
          background-size: 32px 32px;
        }
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background: url(../assets/images/icon-arrow-right-primary.svg) center
        center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}
