@import '../../node_modules/bootstrap/scss/functions';
@import 'variables';
@import '../../node_modules/bootstrap/scss/variables';
// !!! breakpoints needs to be imported after boostrap functions&variables but before mixins !!!
@import 'breakpoints';
@import '../../node_modules/bootstrap/scss/mixins';
// @import '../../node_modules/bootstrap/scss/root';
// @import '../../node_modules/bootstrap/scss/reboot';
// @import '../../node_modules/bootstrap/scss/type';
// @import '../../node_modules/bootstrap/scss/images';
//@import "../../node_modules/bootstrap/scss/code";
//@import '../../node_modules/bootstrap/scss/grid';
// @import '../../node_modules/bootstrap/scss/tables';
// @import '../../node_modules/bootstrap/scss/forms';
// @import '../../node_modules/bootstrap/scss/buttons';
// @import '../../node_modules/bootstrap/scss/transitions';
//@import "../../node_modules/bootstrap/scss/dropdown";
//@import "../../node_modules/bootstrap/scss/button-group";
//@import "../../node_modules/bootstrap/scss/input-group";
// @import '../../node_modules/bootstrap/scss/custom-forms';
// @import '../../node_modules/bootstrap/scss/nav';
//@import "../../node_modules/bootstrap/scss/navbar";
// @import '../../node_modules/bootstrap/scss/card';
//@import "../../node_modules/bootstrap/scss/breadcrumb";
//@import "../../node_modules/bootstrap/scss/pagination";
// @import '../../node_modules/bootstrap/scss/badge';
//@import "../../node_modules/bootstrap/scss/jumbotron";
// @import '../../node_modules/bootstrap/scss/alert';
//@import "../../node_modules/bootstrap/scss/progress";
@import '../../node_modules/bootstrap/scss/media';
// @import '../../node_modules/bootstrap/scss/list-group';
//@import "../../node_modules/bootstrap/scss/close";
// @import '../../node_modules/bootstrap/scss/modal';
// @import '../../node_modules/bootstrap/scss/tooltip';
// @import '../../node_modules/bootstrap/scss/popover';
//@import "../../node_modules/bootstrap/scss/carousel";
// @import '../../node_modules/bootstrap/scss/utilities';
//@import "../../node_modules/bootstrap/scss/print";
//@import "node_modules/bootstrap/scss/functions";
//@import "node_modules/bootstrap/scss/variables";
//@import "node_modules/bootstrap/scss/mixins/_breakpoints";

//@import "../../node_modules/bootstrap/scss/bootstrap";

//tooltip
// stylelint-disable
$tooltip-max-width: 300px;
$tooltip-bg: #373a3c;
$tooltip-arrow-color: $tooltip-bg;
$tooltip-opacity: 1;

//color
@import './colours.scss';

$colors: ();
$colors: map-merge(
  (
    'red': $red,
    'light-green': $light-green,
    'orange': $orange,
    'orange-100': $orange-100,
    'yellow': $yellow,
    'green': $green,
    'white': $white,
    'gray': $gray-100,
    'gray-dark': $gray-200,
    'gray-300': $gray-300,
    'black-haze': $black-haze,
    'outer-space': $outer-space,
    'black': $black,
    'marigold': $marigold,
    'pale-grey': $pale-grey,
    'comet': $comet,
    'onahau': $onahau,
    'periwinkle-gray': $periwinkle-gray,
    'cluey-text': $cluey-text,
    'water-blue': $water-blue,
    'lily-white': $lily-white,
    'tango': $tango,
    'rose-sharon': $rose-sharon,
    'moon-raker': $moon-raker,
    'foird': $foird,
    'punch': $punch,
    'ice-two': $iceTwo,
    'light-yellow': $lightYellow,
    'blaze-orange': $blaze-orange,
  ),
  $colors
);

$theme-colors: ();
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'third': $third,
    'icon-tick': $icon-tick,
    'icon-untick': $icon-untick,
    'cluey-text': $cluey-text,
    'spanish-white': $spanish-white,
    'pale-grey': $pale-grey,
    'periwinkle-gray': $periwinkle-gray,
    'white': $white,
    'lily-white': $lily-white,
    'gray-300': $gray-300,
    'marigold': $marigold,
    'punch': $punch,
    'black-haze': $black-haze,
    'tango': $tango,
    'ebony-clay': $ebony-clay,
    'hawkes-blue': $hawkes-blue,
    'forget-me-not': $forget-me-not,
    'trinidad': $trinidad,
    'dandelion': $dandelion,
    'outer-space': $outer-space,
    'ice-two': $iceTwo,
    'aqua': $aqua-spring,
    'light-yellow': $lightYellow,
  ),
  $theme-colors
);
// stylelint-enable

// Spacing
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 4px;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.5,
    ),
    2: (
      $spacer * 1,
    ),
    3: (
      $spacer * 2,
    ),
    4: (
      $spacer * 3,
    ),
    5: (
      $spacer * 4,
    ),
    6: (
      $spacer * 5,
    ),
    7: (
      $spacer * 6,
    ),
    8: (
      $spacer * 7,
    ),
    9: (
      $spacer * 8,
    ),
    10: (
      $spacer * 9,
    ),
    11: (
      $spacer * 10,
    ),
  ),
  $spacers
);

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints, '$grid-breakpoints');
@include media-breakpoint-between($grid-breakpoints, '$grid-breakpoints');
// @include media-breakpoint-down($grid-breakpoints, "$grid-breakpoints"); error not working

@import './breakpoints';
@import './typography-constants.scss';

//** Background color for `<body>`.
$body-bg: $white;
//** Global text color on `<body>`.
//$text-color:            $gray-dark !default;

// border
$input-border-color: $gray-400 !default;
$line: 2px solid #d7d7e6;

//** Global textual link color.
$link-color: $primary;
//** Link hover color set via `darken()` function.
$link-hover-color: darken($link-color, 15%) !default;
//** Link hover decoration.
$link-hover-decoration: none;
// $font-weight-light:           300 !default;
// $font-weight-normal:          400 !default;

// $font-weight-base:            $font-weight-normal !default;
// $line-height-base:            1.71;

// Components
//
// Define common padding and border radius sizes and more.
$border-width: 1px;
$border-width-double: $border-width * 2;
$border-width-triple: $border-width * 3;
$border-width-wide: $border-width * 4;
$border-width-super: $border-width * 8;
$border-color: #d7d7e6;

$border-radius: 0.25rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.2rem !default;
$border-radius-xl: 0.5rem !default;

$rounded-pill: 50rem !default;

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;

$component-active-color: $white !default;
$component-active-bg: theme-color('primary');

$caret-width: 0.3em !default;
$caret-vertical-align: $caret-width * 0.85 !default;
$caret-spacing: $caret-width * 0.85 !default;

$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;

$embed-responsive-aspect-ratios: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$embed-responsive-aspect-ratios: join(
  ((21 9), (16 9), (4 3), (1 1)),
  $embed-responsive-aspect-ratios
);

// Navs

$nav-link-padding-y: 0.5rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-disabled-color: $gray-600 !default;

$nav-tabs-border-color: $border-color;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $gray-700 !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: $border-color $border-color
  $nav-tabs-link-active-bg !default;

$nav-pills-border-radius: $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg: $component-active-bg !default;

$nav-divider-color: $gray-200 !default;
$nav-divider-margin-y: $spacer / 2 !default;

$toast-border-width: 2px;

// Cards

$card-spacer-y: 1px;
// $card-spacer-x:                     1px;

// buttons
$btn-font-family: $font-family-soft;
$btn-border-radius: 1000px;
$btn-font-size: $font-size-smallest;
$btn-padding-x: 0.75rem;
$btn-padding-y: 0.5rem;
$btn-font-weight: bold;
$btn-disabled-opacity: 0.4;
$btn-line-height: 1;

//navbar
$navbar-padding-x: 0.75rem;

//form elements
$checkbox-color: $primary;

$text-muted: #999999;
