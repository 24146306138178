// cluey icons (Not able to use class icon)
.ci {
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 36px;
    height: 36px;
  }

  &--circle-border {
    &:before {
      border: solid $border-width map-get($colors, 'moon-raker');
      border-radius: 50%;
    }
  }

  &--bg-moon-raker {
    &:before {
      background-color: $moon-raker;
      border-radius: 50%;
    }
  }

  &--bg-peach {
    &:before {
      background-color: $peach;
      border-radius: 50%;
    }
  }

  &--question-mark {
    &:before {
      background: url(../assets/images/icon-question-mark.svg) center center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &--tick {
    &:before {
      background: url(../assets/images/icon-tick-round.svg) center center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &--calendar-1 {
    &:before {
      background: url(../assets/images/icon-calendar-1.svg) center center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
    }
  }

  &--calendar-2 {
    &:before {
      background: url(../assets/images/icon-calendar-2.svg) center center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
    }
  }

  &--calendar-3 {
    &:before {
      background: url(../assets/images/icon-calendar-3.svg) center center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
    }
  }

  &--chat {
    &:before {
      background: url(../assets/images/icon-chat.svg) center center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
    }
  }

  &--payment {
    &:before {
      background: url(../assets/images/icon-payment.svg) center center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
    }
  }

  &--pause {
    &:before {
      background: url(../assets/images/icon-pause.svg) center center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
    }
  }

  &--calendar-slash {
    &:before {
      background-image: url(../assets/images/icon-calendar-slash.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
    }
  }

  &--calendar-change {
    &:before {
      background-image: url(../assets/images/icon-calendar-change.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
    }
  }

  &--phone {
    &:before {
      left: -30px;
      top: 50%;
      transform: translateY(-50%);
      width: 20px !important;
      height: 20px !important;
      background: url(../assets/images/icon-phone.svg) center center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  @include media-breakpoint-up(lg) {
    &:before {
      width: 74px;
      height: 74px;
    }

    &--question-mark,
    &--tick {
      &:before {
        width: 88px;
        height: 88px;
      }
    }

    &--calendar-1,
    &--calendar-2,
    &--calendar-3,
    &--chat,
    &--payment,
    &--pause,
    &--calendar-slash,
    &--calendar-change {
      &:before {
        background-size: 35px 35px;
      }
    }
  }
}
