math-field::part(container) {
  height: 40px;
}

/* Use the classes below to style the different MathField parts/components

math-field::part(content) {
}

math-field::part(menu-toggle) {

}

math-field::part(virtual-keyboard-toggle) {

}
*/