//shadow
$shadow-light: 0 3px 24px 0 rgba(0, 0, 0, 0.08);
$shadow-dark: 0 3px 8px 0 rgba(0, 0, 0, 0.16);

// loading animation
$cluey-loading-size: 14px;

//header
$header-height: 80px;
$header-position-top: $header-height;

//layout
$body-min-width: 360px;

// font-weight
$font-weight-bold: 700;
// local variables
$font-weight-medium: 500;

// Modal
$modal-dialog-margin: 16px;

// Custom Select

// This is a custom, non-BS, variable
$common-form-control-height: 3rem;

$custom-select-font-size: 15px;
$custom-select-indicator: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" width="24" height="24" data-name="Layer 2" viewBox="0 0 24 24"><g id="chevron-down" fill="%232b2e45"><path id="Path_7383" d="M12 15.5a1 1 0 0 1-.71-.29l-4-4a1 1 0 0 1 1.42-1.42L12 13.1l3.3-3.18a1 1 0 1 1 1.38 1.44l-4 3.86a1 1 0 0 1-.68.28z" data-name="Path 7383" transform="translate(0 -.176)"/></g></svg>');
$custom-select-background: $custom-select-indicator no-repeat right 10px center;
$custom-select-height: $common-form-control-height;

// Inputs
$input-height: $common-form-control-height;
