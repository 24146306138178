.header {
  font-size: $font-size-medium;
  font-family: $font-family-soft;

  @include media-breakpoint-down(xs) {
    font-size: $font-size-small;
  }

  & .menu-control {
    width: 24px;
    height: 24px;
    font-size: 0;
    line-height: 1;
    background: url(../assets/images/icon-menu.svg) center center no-repeat;
    background-size: contain;
  }

  & .menu {
    @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
      .container {
        padding: 0 !important;
      }
    }

    @media only screen and (max-width: map-get($grid-breakpoints, lg)) {
      &.active {
        display: block !important;
        width: 100%;
        position: absolute;
        top: $header-position-top - $border-width-wide;
        left: 0;
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.12);
        a {
          font-family: $font-family-soft;
          font-weight: $font-weight-bold;
          width: 100%;
          // display: block !important;  // please use d-lg-block when enable this
          line-height: 21px; // to match new portal
          &:after {
            display: none;
          }
        }

        .btn {
          display: none;
        }
      }
    }

    @media only screen and (min-width: map-get($grid-breakpoints, lg)) {
      align-items: center;
    }

    a,
    .btn {
      line-height: 1;
    }

    a {
      position: relative;
      font-size: $font-size-small;
      font-weight: 500;

      &:not(.btn):hover,
      &:not(.btn):focus {
        color: darken(map-get($theme-colors, 'primary'), 15%);
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 1px;
        height: calc(100% - 6px);
        border-radius: 5px;
        background-color: map-get($theme-colors, 'primary');
      }

      &.isNZUser:after {
        display: none;
      }
    }

    .btn {
      letter-spacing: 1.1px;
    }

    .e-dropdown {
      width: 100%;
      max-height: 0;
      transition: all 0.3s;

      &.show {
        max-height: 206px;
      }

      .message {
        font-family: $font-family-soft;
        font-size: $font-size-small;
        color: map-get($colors, 'gray-300');
      }

      .cta {
        max-width: 316px;
        font-family: $font-family-soft;
        font-weight: 500;
        font-size: $font-size-smallest;
        position: relative;

        &:focus {
          outline: none;
        }

        &:hover,
        &:active {
          color: map-get($colors, 'white');
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          right: 5px;
          width: 24px;
          height: 24px;
          background: url(../assets/images/arrow-right.svg) center center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }

    @media only screen and (max-width: map-get($grid-breakpoints, lg)) {
      .a-dropdown {
        &.active {
          background-color: map-get($colors, 'pale-grey');
        }
      }

      .c-dropdown {
        &.show {
          background-color: map-get($colors, 'pale-grey');
        }
      }

      .e-dropdown {
        .inner {
          padding-top: 0 !important;
        }
      }
    }

    @media only screen and (min-width: map-get($grid-breakpoints, lg)) {
      .a-dropdown {
        &:after {
          width: 20px;
          height: 20px;
          background: url(../assets/images/simple-arrow-down.svg) center center;
          background-repeat: no-repeat;
          background-size: contain;
        }

        &.active {
          &:after {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }

      .c-dropdown {
        width: auto !important;
        position: relative;
        line-height: 1;
      }

      .e-dropdown {
        width: 316px;
        position: absolute;
        top: 15px;
        left: -316px;
        background-color: map-get($colors, 'white');

        &.show {
          max-height: 236px;
        }

        .lock {
          position: relative;
          display: block;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          margin: auto;
          background-color: map-get($colors, 'onahau');

          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 18px;
            height: 24px;
            background: url(../assets/images/icon-lock.svg) center center;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }

        .message {
          font-size: $font-size-medium;
          line-height: 1.5;
        }
      }

      .inner {
        border: $border-width solid map-get($colors, 'periwinkle-gray');
        border-radius: 5px;
        box-shadow: 0 3px 4px 0 rgba(79, 83, 115, 0.06);
      }
    }
  }
}
