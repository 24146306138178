.enrolment-status {
  padding: 22px 16px;

  .cta-wrapper {
    text-align: center;
  }
}

@include media-breakpoint-up(lg) {
  .enrolment-status {
    padding: 28px 60px;

    .cta-wrapper {
      text-align: right;
      button {
        margin-left: auto;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .enrolment-status {
    .cta-wrapper {
      button {
        width: 100%;
      }
    }
  }
}
