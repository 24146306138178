// Overriding styles for the modal
// Check this file first before overriding: node_modules\bootstrap\scss\_modal.scss
// Provide a reason why it's necessary

// Modal backdrop doesn't have a variable to override width/height.
// They need to be overridden because iphone 5 size (320px) shows only part of the backdrop.
.modal-backdrop {
  width: 200vw;
  height: 200vh;
}

.cluey-modal {
  .modal-content {
    border: none;
    border-top: 8px solid $bloodorange_shade_04;
    border-radius: 8px;
  }

  .modal-body {
    padding: 0;
  }
}

@include media-breakpoint-up(lg) {
  .cluey-modal {
    .modal-dialog {
      max-width: 100%;
    }
  }
}
