#ball-0 {
  background-color: #ff6600;
  -webkit-animation-name: bounce;
  -webkit-animation-delay: .5s;
  -webkit-animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
}
#ball-1 {
  background-color: #ffc300;
  -webkit-animation-name: bounce;
  -webkit-animation-delay: .6s;
  -webkit-animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
}
#ball-2 {
  background-color: #7f43e0;
  -webkit-animation-name: bounce;
  -webkit-animation-delay: .7s;
  -webkit-animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
}
#ball-3 {
  background-color: #1d1693;
  -webkit-animation-name: bounce;
  -webkit-animation-delay: .8s;
  -webkit-animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
}
#ball-4 {
  background-color: #ff6600;
  -webkit-animation-name: bounce;
  -webkit-animation-delay: .9s;
  -webkit-animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(max(10px, 50%));
  }
  60% {
    transform: translateY(max(-10px, -50%));
  }
  80% {
    transform: translateY(0);
  }
}

@-webkit-keyframes bounce {
  0% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(max(10px, 50%));
  }
  60% {
    transform: translateY(max(-10px, -50%));
  }
  80% {
    transform: translateY(0);
  }
}
