.hub {
  .list-item--us {
    .status {
      &--session,
      &--payment {
        width: 98px;
      }

      &--session {
        background-color: #e65100;
      }

      &--payment {
        background-color: $punch;
      }
    }

    .detail {
      .label-group {
        position: absolute;
        top: 0;
        right: 0;
        color: $water-blue;
        background-color: $lily-white;
      }
    }
  }

  .list-item--cs {
    .detail {
      .label-group {
        position: absolute;
        top: 0;
        right: 0;
        color: $water-blue;
        background-color: $lily-white;
      }
    }

    .actions {
      .btn {
        text-align: left !important;
      }
    }
  }

  .list-item--us,
  .list-item--cs {
    .payment-indicator--paid,
    .payment-indicator--pending {
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 12px;
        height: 12px;
      }
    }

    .payment-indicator--paid {
      color: $apple;

      &:before {
        background: url(../../assets/images/icon-tag--tango.svg) center center;
        background-repeat: no-repeat;
      }
    }

    .payment-indicator--pending {
      color: $gray-300;

      &:before {
        background: url(../../assets/images/icon-tag--dust.svg) center center;
        background-repeat: no-repeat;
      }
    }
  }

  .pagination {
    .page-item {
      height: 32px;
      line-height: 32px;

      &:hover,
      &:active {
        background-color: $pale-grey !important;
      }

      &--prev,
      &--next {
        img {
          margin-left: -4px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .hub {
    .list-item--cs {
      .date {
        width: 20%;
        min-width: 96px;
      }

      .detail {
        position: relative;
        width: 50%;
      }

      .actions {
        width: 30%;
        text-align: right;

        .btn {
          text-align: right !important;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .hub {
    .list-item--us {
      .date,
      .status {
        width: 15%;
        min-width: 96px;
      }

      .detail {
        position: relative;
        width: 50%;
      }

      .actions {
        width: 20%;
        .btn {
          max-width: 140px;
        }
      }

      .payment-indicator {
        margin-left: 30%;
        margin-right: 20%;
      }
    }

    .list-item--cs {
      .payment-indicator {
        margin-left: 20%;
        margin-right: 30%;
      }
    }
  }
}
