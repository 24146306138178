@import './nav.scss';
@import './content.scss';
@import './sessions.scss';
@import './enrolments.scss';
@import './promo-banner.scss';
@import './auth.scss';
@import './form.scss';
@import './Enrolment/enrolment';

@import './marketing-promo';

html {
  scroll-behavior: smooth;
}

.hub {
  color: $outer-space;

  //.header-container {
  //  .dropdown {
  //    position: static;
  //  }
  //
  //  .menu-control {
  //    width: 24px;
  //    height: 24px;
  //    font-size: 0;
  //    line-height: 1;
  //    background: url(../../assets/images/icon-menu.svg) center center no-repeat;
  //    background-size: contain;
  //
  //    &:active,
  //    &:focus {
  //      background-color: transparent;
  //      outline: none;
  //      box-shadow: none;
  //    }
  //  }
  //
  //  .menu-body {
  //    box-shadow: 0 6px 6px 0 rgba(17, 17, 17, 0.12);
  //  }
  //}

  .body-container {
    margin-bottom: $header-height; // leave space for footer

    .content-container {
      width: 100%;
      min-height: 50vh;
    }
  }

  .footer-container {
    // Note: background image is requested to be removed. https://clueylearning.atlassian.net/browse/CUP-437
    // Not sure whether we will put it back or not.
    //height: 300px;
    //background-image: url(../assets/images/footer-image.svg);
    //background-position: 80% 0;
    //background-repeat: no-repeat;
  }

  .divider {
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      top: 55%;
      width: 100%;
      height: 1px;
      background-color: $periwinkle-gray;
    }
  }
}

@include media-breakpoint-up(lg) {
  .hub {
    .body-container {
      margin-bottom: 0;
      min-height: 70vh;

      .nav-container {
        width: 264px;
      }

      .content-container {
        width: calc(100% - 264px - 40px);

        &.no-sidenav {
          max-width: 760px;
        }
      }
    }

    //.footer-container {
    //  height: 460px;
    //}
  }
}

@include media-breakpoint-up(xxl) {
  .hub {
    .body-container {
      .nav-container {
        width: 428px;
      }

      .content-container {
        width: calc(100% - 428px - 40px);
      }
    }
  }
}

#zuora_payment {
  iframe {
    background: none;
  }
}
