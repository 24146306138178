.page-error {
  font-family: $font-family-soft;
  color: $gray-300;
  .inner {
    max-width: 712px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);

    @include media-breakpoint-up(md) {
      padding: 72px !important;
    }

    @include media-breakpoint-up(lg) {
      padding: 92px !important;
    }
  }

  .logo {
    width: 100px;
  }

  .img-wrap {
    width: 88px;
    height: 88px;
    border-radius: 50%;

    img {
      width: 48px;
      height: 48px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  h3 {
    letter-spacing: 0.24px;
    @include media-breakpoint-down(md) {
      font-size: $font-size-large;
    }
  }

  p {
    letter-spacing: 0.2px;
    @include media-breakpoint-up(md) {
      font-size: $font-size-medium;
    }

    @include media-breakpoint-up(lg) {
      font-size: $font-size-large;
    }
  }
  .button {
    font-size: $font-size-small;
    letter-spacing: normal;
    @include media-breakpoint-up(md) {
      font-size: $font-size-medium;
    }

    @include media-breakpoint-up(lg) {
      min-width: 222px;
    }
  }

  .c-section--phone {
    p {
      font-weight: $font-weight-medium !important;
      letter-spacing: normal;
    }
  }
}
