.timeline {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: calc(15% + 3px);
    bottom: 0;
    width: 0;
    border: 0.9px solid;
    color: #dedfea;
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }

  .animate {
    .bounce-in {
      visibility: visible;
      -webkit-animation: bounce-in 0.4s;
      -moz-animation: bounce-in 0.4s;
      animation: bounce-in 0.4s;
    }
  }

  .body-container {
    position: relative;
    margin-left: 30px;

    @include media-breakpoint-down(xs) {
      margin-left: 0;
    }
  }

  .entry {
    clear: both;
    text-align: left;
    position: relative;

    .title {
      .circle {
        position: absolute;
        width: 42px;
        height: 42px;
        background-color: white;
        border-radius: 100%;
        top: 0;
        right: -18px;
        box-sizing: content-box;

        @include media-breakpoint-down(sm) {
          right: unset;
          left: -2px;
          width: 32px;
          height: 32px;
        }

        @include media-breakpoint-down(xs) {
          right: unset;
          left: 14px;
          width: 20px;
          height: 20px;
        }

        .icon {
          font-size: $font-size-larger;
          text-align: center;
          position: absolute;
          margin-top: 10px;
          margin-left: 10px;
          color: theme-color('danger');

          @include media-breakpoint-down(sm) {
            font-size: $font-size-small;
            margin-top: 8px;
            margin-left: 8px;
          }

          @include media-breakpoint-down(xs) {
            font-size: $font-size-smallest;
            margin-top: 5px;
            margin-left: 5px;
          }
        }
      }
    }
  }

  // the icon for timeline-begin
  & .no-children {
    & .title {
      & .circle {
        top: 20px !important;
      }
    }
  }
}

.entry {
  .title {
    float: left;
    width: 15.5%;
    position: relative;
    height: 32px;
  }
  &:last-child {
    .body {
      margin-bottom: 0 !important;
    }
  }
}

@include media-breakpoint-down(sm) {
  .timeline:before {
    left: calc(1% + 8px);
  }
}

@include media-breakpoint-down(xs) {
  .timeline:before {
    left: calc(1% + 19.6px);
  }
}

@-o-keyframes bounce-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@-webkit-keyframes bounce-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes bounce-in {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }

  100% {
    -moz-transform: scale(1);
  }
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.timeline--wrapper {
  width: calc(100% - 24px);
  padding: 12px;
}

.timeline-animate .entry .bounce-in {
  visibility: visible;
  -webkit-animation: bounce-in 0.4s;
  -moz-animation: bounce-in 0.4s;
  animation: bounce-in 0.4s;
}

.entry .body {
  // margin: 0 0 3em;
  float: right;
  // width: 66%;
  width: 84.5%;
  color: #333;
}

@-o-keyframes bounce-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@-webkit-keyframes bounce-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes bounce-in {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }

  100% {
    -moz-transform: scale(1);
  }
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@include media-breakpoint-down(sm) {
  .entry .title {
    float: left;
    width: 70%;
  }

  .entry .title:before {
    top: 15%;
    left: 3px;
    right: auto;
    z-index: 2;
  }

  .entry .body {
    margin: 10px 0 40px;
    float: right;
    width: 100%;
  }
}

@include media-breakpoint-down(xs) {
  .timeline-item-date {
    margin-top: -5px;
  }
}

.timeline-date {
  font-size: $font-size-medium;
  font-family: $font-family-soft;
}

.timeline-time {
  font-size: $font-size-small-extra;
  white-space: nowrap;
}

.timeline-dateTime-md {
  font-size: $font-size-medium;
  line-height: 1.78;
  color: $gray-300;
  @include media-breakpoint-down(xs) {
    margin-top: -5px;
    font-size: $font-size-small;
  }
  b {
    vertical-align: sub;
  }
}

.timeline-dateTime {
  display: block;
  @media only screen and (max-width: map-get($grid-breakpoints, md)) {
    display: none;
  }
}

.timeline-begin {
  text-align: center;
  margin-left: 150px;
  img {
    width: 47%;
    margin-top: -6%;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 3%;
  }
}

.load-more {
  margin-left: 22%;
  .button {
    width: 271.9px;
  }
  p {
    font-size: $font-size-small;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 4%;
  }
}

.load-more-circle {
  width: 9px;
  height: 9px;
  margin-left: 14.95%;
  @include media-breakpoint-down(sm) {
    margin-left: 1.5%;
  }
}
