.code-camp-logo,
.code-camp-kids {
  width: 160px;
  height: auto;

  img {
    width: 100%;
    height: 100%;
  }
}

.code-camp-form {
  padding-top: 40px;
  padding-bottom: 44px;

  .code-camp-postcode {
    width: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .code-camp-logo {
    width: 300px;
  }

  .code-camp-form {
    padding-top: 66px;
    padding-bottom: 84px;

    .code-camp-postcode {
      width: 130px;
    }
    .code-camp-kids {
      width: 445px;
      right: 0;
      bottom: 0;
    }
  }
}
