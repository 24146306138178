@import 'variables';
/**
 * Morph loading animation
 * Credit to https://webkul.github.io/csspin/
 */
.cp-spinner {
  // width: 48px;
  // height: 48px;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
}
.cp-hue {
  display: inline-block;
  box-sizing: border-box;
  background: theme-color('primary'); // #f56151;
  border-radius: 50%;
  animation: cp-hue-animate 1s ease-in-out infinite;
  width: $cluey-loading-size;
  height: $cluey-loading-size;
}
.cp-hue:before {
  border-radius: 0 calc($cluey-loading-size / 2) calc($cluey-loading-size / 2) 0;
  content: ' ';
  width: calc($cluey-loading-size / 2);
  height: $cluey-loading-size;
  display: inline-block;
  box-sizing: border-box;
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  animation: cp-hue-animate-before 1s ease-in-out infinite;
}
@keyframes cp-hue-animate {
  0% {
    background: rgba(theme-color('primary'), 0.5);
  }
  25% {
    background: theme-color('primary');
  }
  50% {
    background: rgba(theme-color('primary'), 0.5);
  }
  75% {
    background: theme-color('primary');
  }
  100% {
    background: rgba(theme-color('primary'), 0.5);
  }
}
@keyframes cp-hue-animate-before {
  0% {
    transform: rotateY(0);
    transform-origin: left center;
    opacity: 0.5;
  }
  30%,
  70% {
    transform: rotateY(180deg);
    transform-origin: left center;
    opacity: 0.2;
  }
  100% {
    transform: rotateY(0);
    opacity: 0.5;
  }
}
/**
 * Pulsating icon animation
 */
@keyframes pulsating {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 17px rgba(255, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}
