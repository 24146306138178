// font
// Font, line-height, and color for body text, headings, and more.
// stylelint-disable value-keyword-case
$font-family-nova: proxima-nova, sans-serif;
$font-family-soft: proxima-soft, sans-serif;

// stylelint-enable value-keyword-case
$font-size-smallest: 11px;
$font-size-smaller: 13px;
$font-size-small-extra: 14px;
$font-size-small: 15px;
$font-size-medium: 18px;
$font-size-large: 20px;
$font-size-larger: 22px;
$font-size-largest: 24px;

$h1-font-size: 40px;
$h2-font-size: 36px;
$h3-font-size: 24px;
// $h4-font-size:                24px;
// $h5-font-size:                20px;
// $h6-font-size:                16px;
