.marketing-promo {
  &__container {
    background-color: $gin-fizz;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -80px;
      top: -80px;
      width: 200px;
      height: 200px;
      background-color: $secondary;
      border-radius: 50%;
      opacity: 0.12;
    }
  }

  &-cta {
    // Note, please don't use bg-white class. This will have a different hover state
    background-color: $white;
    max-width: 250px;

    &:active,
    &:focus {
      background-color: $white !important;
      color: $primary !important;
      outline: none !important;
    }
  }
}
