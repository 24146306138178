.submitBtn {
  margin-bottom: 18px;
  letter-spacing: normal !important;

  button {
    padding: 0;
    letter-spacing: normal !important;
  }
}

@include media-breakpoint-up(lg) {
  .submitBtn {
    margin-bottom: 32px;
  }
}
