body {
  // min-width: $body-min-width;
  height: 100vh;
}

.container {
  //padding: 0;
  // @include media-breakpoint-down(sm) {
  //   max-width: 90%;
  // }
  // @include media-breakpoint-down(xs) {
  //   max-width: 100%;
  // }

  .past-activities-title {
    max-width: 220px;
    line-height: 1.14;
    z-index: 2;
    margin-left: 18%;
    @include media-breakpoint-down(md) {
      margin-left: 170px;
    }
    @include media-breakpoint-down(sm) {
      margin-left: 83px;
    }
    @include media-breakpoint-down(xs) {
      margin-left: 47px;
    }
  }

  &.manage-pause-container {
    margin-bottom: 132px;
  }
}

#root {
  // font-family: $font-family-soft;
  // color: $gray-300;
  height: 100%;
}

.header-container {
  position: relative;
  z-index: 1;
  height: $header-height;
}

// .body-container {
//   min-height: 50vh;
// }
