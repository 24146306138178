.list-sessions {
  font-family: $font-family-soft;
  font-size: $font-size-small;
  color: $cluey-text;

  .list-group-item {
    height: auto;
    line-height: 1;
    border: $border-width solid $periwinkle-gray;
    border-bottom: 0;

    &:last-of-type {
      border-bottom: $border-width solid $periwinkle-gray;
    }

    * {
      line-height: 1;
    }
  }

  .heading {
    background-color: $pale-grey;

    .title {
      font-size: $font-size-medium;

      @media only screen and (min-width: map-get($grid-breakpoints, md)) {
        font-size: $font-size-largest;
      }
    }
  }

  .filters {
    margin-left: auto;

    .form-check {
      position: relative;
      input[type='checkbox'] {
        display: none;
      }

      label {
        cursor: pointer;
        position: relative;

        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
        }

        &:before {
          width: 18px;
          height: 18px;
          top: -2px;
          left: 0;
          border: $border-width-double solid $checkbox-color;
          border-radius: $border-radius;
        }
      }

      input[type='checkbox']:checked + label {
        &:after {
          width: 24px;
          height: 24px;
          top: -5px;
          left: -3px;
          background: url(../assets/images/checkbox-checked.svg) center center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }

  .list-group-item:not(.heading) {
    .inner {
      position: relative;
    }

    .date,
    .student,
    .detail,
    .type,
    .actions {
      width: 100%;
    }

    .date,
    .student {
      font-weight: $font-weight-bold;
    }

    .status {
      min-width: 92px;
      text-align: center;
      font-size: $font-size-small-extra;
      font-weight: normal;
      line-height: 1rem;
      color: $white;
      background-color: $tango;
      border-radius: $border-radius;
    }

    .holiday-indicator {
      position: absolute;
      left: -36px;
      width: 24px;
      height: 24px;
      border: $border-width-double solid $white;
      background-color: $white;
      background-image: url(../assets/images/icon-info.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      outline: none;

      &:hover,
      &:active {
        & > span {
          display: block !important;
        }
      }

      span {
        position: absolute;
        left: 50px;
        top: 50%;
        transform: translateY(-50%);
        width: 200px;
        height: auto;
        line-height: 1.6;
        color: theme-color('white');
        background-color: $gray-300;

        &:after {
          content: '';
          display: block;
          position: absolute;
          left: -11px;
          top: 50%;
          transform: translateY(-50%);
          border-style: solid;
          border-width: 10px 12px 10px 0;
          border-color: transparent $gray-300 transparent transparent;
        }
      }
    }

    .type {
      @include media-breakpoint-down(md) {
        position: absolute;
        top: 0;
        right: 0;
      }

      & > span {
        font-size: $font-size-small-extra;
        color: $water-blue;
        background-color: $lily-white;
        border-radius: $border-radius;
      }
    }

    .actions {
      .btn {
        font-family: $font-family-soft;
        letter-spacing: 1.1px;
        line-height: 14px;
      }
    }

    .cta--join {
      & + span {
        color: $rose-sharon;
        font-weight: $font-weight-bold;
        text-align: center;
      }
    }

    .join-session-info-text {
      font-size: $font-size-smaller;
      font-weight: normal;
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, lg)) {
  .list-sessions {
    .list-group-item:not(.heading) {
      .date,
      .student,
      .detail {
        margin-bottom: 0 !important;
      }

      .date {
        width: 21%;
        min-width: 220px;
        margin-right: 16px;

        .status {
          margin-left: auto;
        }
      }

      .student {
        width: 11%;
      }

      .detail {
        width: 30%;
        min-width: 242px;
      }

      .type {
        width: 6%;
      }

      .actions {
        width: 32%;

        .btn {
          max-width: 120px;
        }
      }

      &.private {
        .actions {
          width: 38%;
        }
      }
    }
  }
}

.session--failed-payment {
  font-size: $font-size-small-extra;
  color: $punch;

  &--triggered {
    color: $water-blue;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-small;
  }

  .btn-link {
    font-family: $font-family-soft;
    font-size: $font-size-small-extra;
    line-height: inherit;
    letter-spacing: normal;
    margin-top: -3px;
    text-decoration: underline;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-small;
    }
  }

  .button {
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    & > p {
      width: calc(100% - 260px);
    }

    & > .button {
      width: 260px;
    }
  }
}
