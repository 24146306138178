.full-page-loading {
  z-index: 3;
  font-size: $font-size-large;
  min-height: 30vh;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-medium;
  }
  @include media-breakpoint-down(sm) {
    font-size: $font-size-smallest;
  }
  .cluey-loading-block {
    height: 150px;
  }
  .cluey-loading {
    @extend .cp-spinner;
    @extend .cp-hue;
  }
}

.block-loader-container {
  width: 150px;
  height: 17px;
  min-height: 17px;
}
