.opacity {
  &-disabled {
    opacity: $btn-disabled-opacity;
  }
}

$font-sizes: 9, 10, 11, 12, 13, 14, 15, 16, 18, 20, 24, 28, 32, 36, 48;
$font-sizes-md: 11, 12, 14, 15, 16, 18, 20, 21, 22, 24, 26, 28, 32, 36, 40, 48;
$font-sizes-lg: 11, 12, 14, 15, 16, 18, 20, 21, 22, 24, 26, 28, 32, 36, 40, 48;
$font-sizes-xl: 11, 12, 14, 15, 16, 18, 20, 21, 22, 24, 26, 28, 32, 36, 40, 48;

.fs {
  @each $font-size in $font-sizes {
    &-#{$font-size}px {
      font-size: #{$font-size}px !important;
    }
  }
}

@include media-breakpoint-up(md) {
  .fs {
    @each $font-size in $font-sizes-md {
      &-md-#{$font-size}px {
        font-size: #{$font-size}px !important;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .fs {
    @each $font-size in $font-sizes-lg {
      &-lg-#{$font-size}px {
        font-size: #{$font-size}px !important;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .fs {
    @each $font-size in $font-sizes-xl {
      &-xl-#{$font-size}px {
        font-size: #{$font-size}px !important;
      }
    }
  }
}

// border
.border-width-2px {
  border-width: 2px !important;
}

.border-width-3px {
  border-width: 3px !important;
}

.border-width-4px {
  border-width: 4px !important;
}

.border-radius-0 {
  border-radius: 0;
}

.border-radius-8px {
  border-radius: 8px;
}

.border-ice {
  border-color: $ice !important;
}

// Note, this utility class is added since Lorenzo uses this border top quite often
.border-top-8px-orange {
  border-top: solid 8px map-get($colors, 'blaze-orange');
}

.box-shadow-8px-025 {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
}

// font family
.proxima-nova {
  font-family: $font-family-nova;
}

.proxima-soft {
  font-family: $font-family-soft;
}

.ls-normal {
  letter-spacing: normal !important;
}

.ls-1px {
  letter-spacing: 1px !important;
}

.ls-n0-25px {
  letter-spacing: -0.25px;
}

.lh-inherit {
  line-height: inherit;
}

.lh-1 {
  line-height: 1;
}

.lh-16px {
  line-height: 16px;
}

.lh-42px {
  line-height: 42px;
}

.lh-24px {
  line-height: 24px;
}

.lh-25px {
  line-height: 25px;
}

.lh-1-14 {
  line-height: 1.14;
}

.lh-1-17 {
  line-height: 1.17;
}

.lh-1-2 {
  line-height: 1.2;
}

.lh-1-25 {
  line-height: 1.25;
}

.lh-1-33 {
  line-height: 1.33;
}

.lh-1-67 {
  line-height: 1.67;
}

.pointer-events-none {
  pointer-events: none !important;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.us-none {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.of-hidden {
  overflow: hidden;
}

.min-height-100 {
  min-height: 100%;
}

// background color
// Note, not using bg-color to avoid conflicts with bootstrap utility classes
.bgc-white {
  background-color: $white;
}

.bgc-colonial-white {
  background-color: $colonial-white;
}

.bgc-aqua-spring {
  background-color: $aqua-spring;
}

.bgc-forget-me-not {
  background-color: $forget-me-not;
}

.bgc-granny-apple {
  background-color: $granny-apple;
}

.bgc-ice {
  background-color: $ice;
}

.bgc-slate_shade_01 {
  background-color: $slate_shade_01;
}

.bgc-red_shade_04 {
  background-color: $red_shade_04;
}

// text colors:
.text-cobalt {
  color: $cobalt;
}

.text-gallery {
  color: $gallery;
}

.text-dusty-gray {
  color: $dusty-gray;
}

.text-line-through {
  text-decoration: line-through;
}

.text-parsley {
  color: $parsley;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: solid 1px $pumice;
  background-color: $gallery;
}

// This is required quite often. Please replace styles with this new utility class when possible
.w-mobile-100 {
  width: 100%;
}

@include media-breakpoint-up(md) {
  .w-mobile-100 {
    width: auto;
  }
}

.mw-320px {
  max-width: 320px;
}

@include media-breakpoint-up(md) {
  .mw-md-350px {
    max-width: 350px;
  }
}

// list
.list-style-pi {
  list-style-position: inside;
}

.list-style-po {
  list-style-position: outside;
}

.list-style-none {
  list-style: none;
  padding: 0;
  margin: 0;
}
