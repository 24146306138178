.hub {
  .content-container {
    .content-head {
      .nav-links {
        a.active,
        div.active {
          color: $ebony-clay;
        }

        a:not(.active) {
          transition: background-color 0.5s linear;
          background-color: $pale-grey;

          &:hover,
          &:active {
            background-color: darken($pale-grey, 10%);
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .hub {
    .content-container {
      .content-head {
        .nav-links {
          height: 40px;

          a.active,
          div.active {
            background-color: $pale-grey;
          }

          a:not(.active) {
            background-color: $white;
            font-weight: $font-weight-bold;
          }
        }
      }
    }
  }
}

.option-card {
  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none !important;
    outline: 2px solid darken($slate_shade_01, 10%);

    .text-primary-hover {
      color: $blue_shade_06;
    }
  }

  &__title {
    color: $grey_shade_06;
  }

  .text-primary-hover {
    color: $primary;
  }
}
