.c-section {
  &--banner {
    @include media-breakpoint-up(lg) {
      padding-bottom: 144px !important;
    }
  }

  &--with-top {
    border-top: $border-width-super solid map-get($colors, 'marigold');
  }

  &--phone {
    @include media-breakpoint-up(lg) {
      padding-top: 55px !important;
      padding-bottom: 78px !important;
    }

    p {
      font-family: $font-family-soft;
      font-size: $font-size-medium;
      color: map-get($colors, 'gray-300');

      @include media-breakpoint-up(lg) {
        max-width: 569px;
        font-size: $font-size-larger;
      }
    }
  }

  &--go-back {
    button {
      font-size: $font-size-small;
      color: theme-color('primary');
      &:first-of-type {
        &:before {
          display: none;
        }
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: $border-width;
        height: 50%;
        background-color: theme-color('primary');
      }
    }
  }
}
