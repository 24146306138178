.strand {
  .section-title {
    height: 48px;
    line-height: 48px;
    border: solid 1px $border-color;
    background-color: $gray-100;
    font-size: $font-size-small-extra;
    font-family: $font-family-soft;
    color: $gray-200;
  }
  .tutor-feedback {
    font-size: $font-size-small;
    line-height: 1.6;
    text-align: left;
    color: $gray-300;
  }
  .student-self-evaluation {
    font-family: $font-family-soft;
  }
}
