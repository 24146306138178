.welcome {
  font-size: $font-size-small;

  //.nav-link {
  //  &.active {
  //    background-color: $component-active-bg !important;
  //    color: $component-active-color;
  //  }
  //}

  h3 {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 32px;
      height: 32px;
      background: url(../assets/images/icon-hand-wave.svg) center center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  p {
    line-height: 1.6;
  }

  @media only screen and (min-width: map-get($grid-breakpoints, lg)) {
    .enrolments {
      justify-content: space-between;

      &--three-columns {
        justify-content: flex-start;
        .enrolment {
          width: 32%;
          margin-right: 1.5%;

          &:nth-of-type(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }

  .enrolment {
    width: 100%;

    @media only screen and (min-width: map-get($grid-breakpoints, lg)) {
      width: 49%;
    }

    .group {
      position: absolute;
      top: -4px;
      right: 4px;
      font-size: $font-size-small-extra;
      color: map-get($colors, 'water-blue');
      background-color: map-get($colors, 'lily-white');
      border-radius: $border-radius;
    }

    .actions {
      a {
        line-height: 15px;

        & + a {
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 1px;
            height: 11px;
            background-color: map-get($colors, 'periwinkle-gray');
          }
        }
      }
    }
  }
}
