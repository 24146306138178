.btn {
  letter-spacing: 1px;
  &-arrow-right {
    font-family: $font-family-soft;
    letter-spacing: 1.1px;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background: url(../assets/images/arrow-right.svg) center center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &-outline-primary {
    &:focus {
      box-shadow: none;
    }
  }
  &-large {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    padding: 12px 36px;
  }

  &-as-link {
    color: $link-color;
    &:hover {
      text-decoration: underline;
      color: darken($link-color, 15%);
    }
  }
}

a {
  &.btn {
    &:focus {
      box-shadow: none;
    }

    &.disabled {
      pointer-events: unset;

      &:active {
        pointer-events: none;
      }
    }
  }

  &.btn-primary {
    &:focus,
    &:active {
      background-color: theme-color('primary');
    }
  }
}