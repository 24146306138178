$white: #ffffff; // white
$gray-100: #f6f7fa; // background color
$gray-200: #6f7594; // dark 1
$gray-300: #4f5373; // dark 2
$gray-400: #383737; // dark 3

$grays: ();
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
  ),
  $grays
);

$blue: #1496bb;
$orange: #da621e;
$orange-100: #ff5000;
$blaze-orange: #ff6600;
$red: #c02f1d;
$light-green: #dbfbdf;
$yellow: #ffc107 !default;
$green: #23977b;
$black-haze: #e6e7e7;
$black: #000000;
$outer-space: #383c40;
$marigold: #ffc300;
$pale-grey: #f2f5fa;
$comet: #4e5373;
$onahau: #c7eeff;
$periwinkle-gray: #ced2ed;
$cluey-text: #4d516a;
$water-blue: #0a75c6;
$lily-white: #ecfaff;
$tango: #f26b1d;
$rose-sharon: #bf4b00;
$spanish-white: #faf4eb;
$moon-raker: #e3e7fa;
$foird: #3b4a66;
$punch: #d93829;
$blue-violet: #6b56af;
$peach: #ffe1d9;
$ebony-clay: #2b2e45;
$hawkes-blue: #e3f2fd;
$denim: #1565c0;
$apple: #558b2f;
$blue-lagoon: #00838f;
$white-ice: #e0f7fa;
$salomie: #ffe082;
$sail: #bbdefb;
$peach-schnapps: #ffe1d8;
$forest-green: #1b8728;
$forget-me-not: #fff2ed;
$trinidad: #e65100;
$panache: #e8f5e9;
$gin-fizz: #fff8e1;
$dandelion: #ffdb67;
$iron: #d2d5d6;
$dusty-gray: #999999;
$ice: #e6e8e8;
$aqua-spring: #ecfafa;
$colonial-white: #ffefbb;
$pumice: #d8d9d8;
$cobalt: #004bb9;
$granny-apple: #e8fae9;
$gallery: #eeeeee;
$parsley: #196323;

$primary: #004ab9;
$secondary: #ff5000;
$success: #1a8637;
$info: #047cbc;
$warning: #c55103;
$danger: #d32f2f;
$light: $gray-100;
$dark: $gray-200;
$third: #6f5ab1;
$icon-tick: #338645; // tick-icon color
$icon-untick: #b6e2c1; // untick-icon color
$iceTwo: #e8fae9;
$lightYellow: #ffefbb;

// colour variables from websites
$teal_shade_01: #e4f7f6;
$teal_shade_02: #aeebe8;
$teal_shade_03: #74d2d0;
$teal_shade_04: #1a7c74;
$teal_shade_05: #0d544f;
$teal_shade_06: #0b3c39;

$slate_shade_01: #f2f5fa;
$slate_shade_02: #e3e7fa;
$slate_shade_03: #ced2ed;
$slate_shade_04: #4f5373;
$slate_shade_05: #2b2e45;
$slate_shade_06: #16192e;

$purple_shade_01: #f4f2ff;
$purple_shade_02: #b0a1ff;
$purple_shade_03: #9573ff;
$purple_shade_04: #612094;
$purple_shade_05: #612094;
$purple_shade_06: #420055;

$bloodorange_shade_01: #faf4eb;
$bloodorange_shade_02: #fce4c7;
$bloodorange_shade_03: #ffca99;
$bloodorange_shade_04: #ff6600;
$bloodorange_shade_05: #bf4b00;
$bloodorange_shade_06: #92400d;

$red_shade_01: #fff2ed;
$red_shade_02: #ffe1d9;
$red_shade_03: #ffc7ba;
$red_shade_04: #ff5c4a;
$red_shade_05: #d93829;
$red_shade_06: #a12c23;

$green_shade_01: #e8fae9;
$green_shade_02: #bcf7b4;
$green_shade_03: #7feb86;
$green_shade_04: #1b8728;
$green_shade_05: #196323;
$green_shade_06: #17451e;

$blue_shade_01: #ecfafa;
$blue_shade_02: #7fcfff;
$blue_shade_03: #2bb9ff;
$blue_shade_04: #008de8;
$blue_shade_05: #004bb9;
$blue_shade_06: #1d1693;

$yellow_shade_01: #ffefbb;
$yellow_shade_02: #ffdb67;
$yellow_shade_03: #ffc300;
$yellow_shade_04: #f6a11d;
$yellow_shade_05: #a26404;
$yellow_shade_06: #4c3003;

$grey_shade_01: #f2f4f5;
$grey_shade_02: #e6e8e8;
$grey_shade_03: #d2d5d6;
$grey_shade_04: #929596;
$grey_shade_05: #535759;
$grey_shade_06: #383c40;

$lime_shade_01: #f3f7e9;
$lime_shade_02: #ddf2ae;
$lime_shade_03: #bce36f;
$lime_shade_04: #588219;
$lime_shade_05: #40620d;
$lime_shade_06: #2d4214;

$pink_shade_01: #fff2f5;
$pink_shade_02: #ffe0e7;
$pink_shade_03: #ffc7d2;
$pink_shade_04: #d43b52;
$pink_shade_05: #a12a3a;
$pink_shade_06: #66262d;
