.katex {
  font: normal 16px proxima-soft, sans-serif;
}

.katex .base {
  display: inline;
}

.mord.text {
  display: inline-block;
  word-break: break-word;
  white-space: break-spaces;
}
