@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Simian';
  src: url('./assets/fonts/SimianText-Chimpanzee.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

.code-camp-radial-gradient {
  background: radial-gradient(circle at right, #E5338C 0%, #00B0FF 82.81%);
}