@import 'node_modules/bootstrap/scss/mixins';
@import 'src/scss/breakpoints.scss';
@import 'src/scss/colours.scss';
@import 'src/scss/custom-bootstrap.scss';

.tabs {
  position: absolute;
  bottom: -68px; // .container padding-bottom + 1px
}

.tabBtn {
  line-height: 1.14;
  border-top-left-radius: $border-radius-xl;
  border-top-right-radius: $border-radius-xl;
  border: solid 1px $iron;
  border-bottom: none;
  @include media-breakpoint-down(md) {
    width: 50%;
  }

  &:focus {
    outline: none;
  }
}

.tabBtnActive {
  background: $white;
  box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.15);
}

@include media-breakpoint-up(lg) {
  .tabs {
    position: relative;
    bottom: -16px; // .container padding-bottom + 1px
  }
}
