.custom-tab {
  &-container {
    width: 100%;
    margin-bottom: -2px;
  }

  width: 50%;
  background-color: $pale-grey;
  line-height: 1.14;
  border-top-left-radius: $border-radius-xl;
  border-top-right-radius: $border-radius-xl;
  border: solid 1px $iron;
  color: $primary;

  @include media-breakpoint-up(lg) {
    width: auto;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none;
    span {
      transform: scale(1.1);
    }
  }

  &--active {
    color: $outer-space;
    background: $white;
    border: none;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.15);
  }
}
