.promo-banner {
  position: relative;
  margin-bottom: 60px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    width: 100%;
    height: 1px;
    background-color: $ice;
  }
}
