// All form related elements like radios, inputs etc.
@import './colours.scss';

input {
  &.invalid {
    background-image: none !important;
    border-color: $punch !important;
    box-shadow: 0 0 0 0.2rem rgba(217, 56, 41, 0.25); // $punch
  }
}

// Sizing for standard radio
// input[type='radio'] {
//   width: 20px;
//   height: 20px;
// }

// Custom radio
.custom-control-input[type='radio'] {
  // Unchecked state
  & ~ .custom-control-label {
    &::before,
    &::after {
      width: 20px;
      height: 20px;
    }

    &::before {
      border: 2px solid $ebony-clay;
    }
  }

  // Checked state
  &:checked ~ .custom-control-label {
    &::before {
      background-color: $ebony-clay;
    }
  }
}

// Colour of custom selected white radio
.radio-selected-white {
  .custom-control-input[type='radio']:checked ~ .custom-control-label::before {
    border: 2px solid white;
    background-color: $primary;
  }
}

// Custom checkbox
.custom-control-input[type='checkbox'] {
  // Unchecked state
  & ~ .custom-control-label {
    cursor: pointer;
    &::before,
    &::after {
      width: 32px;
      height: 32px;
    }

    &::before {
      border: 2px solid $iron;
      border-radius: 4px;
    }
  }

  // Checked state
  &:checked ~ .custom-control-label {
    &::before {
      border: 2px solid $primary;
      background-color: $white;
    }

    &:after {
      background-image: url(../assets/images/icon-tick--primary.svg);
    }
  }
}

.highlight {
  .custom-control-input[type='checkbox'] {
    // Unchecked state
    & ~ .custom-control-label {
      &::before {
        border-color: $punch;
      }
    }
  }

  & ~ .form-control[type='text'] {
    border-color: $punch;
  }
}

// CH-69 radio button styles from website
$cluey-radio-dimension: 32px;
$cluey-radio-inner-dimension: 22px;
$cluey-radio-border-width: 2px;
// stylelint-disable
$cluey-radio-offset: calc(($cluey-radio-dimension - $cluey-radio-border-width * 2 - $cluey-radio-inner-dimension) / 2);

.cluey-radio__wrapper {
  display: flex;
  align-items: center;
  min-height: $cluey-radio-dimension;
  position: relative;
  margin-bottom: $spacer * 3;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.flow-c {
    margin-bottom: 0;
  }
}

.cluey-radio__wrapper,
.cluey-radio__below-option-content {
  padding-left: $cluey-radio-dimension + $spacer * 4;
}

/* Hide the browser's default radio button */
.cluey-radio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.cluey-radio__checkmark {
  position: absolute;
  top: calc(50% - calc($cluey-radio-dimension / 2));
  left: 0;
  height: $cluey-radio-dimension;
  width: $cluey-radio-dimension;
  background-color: white;
  border-radius: 50%;
  border: $cluey-radio-border-width solid $grey_shade_03;
}
// On mouse-over, add a grey background color
.cluey-radio__wrapper:hover .cluey-radio ~ .cluey-radio__checkmark {
  border: $cluey-radio-border-width solid $grey_shade_04;
}
// When the radio button is checked, add a blue background
.cluey-radio__wrapper .cluey-radio:checked ~ .cluey-radio__checkmark {
  border: $cluey-radio-border-width solid $blue_shade_05;
}
// When the radio is checked AND hovered, add a dark blue background
.cluey-radio__wrapper:hover {
  .cluey-radio:checked ~ .cluey-radio__checkmark {
    border: $cluey-radio-border-width solid $blue_shade_06;
  }
  .cluey-radio__checkmark:after {
    background: $blue_shade_06;
  }
}
// Create the indicator (the dot/circle - hidden when not checked)
.cluey-radio__checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
// Show the indicator (dot/circle) when checked
.cluey-radio__wrapper .cluey-radio:checked ~ .cluey-radio__checkmark:after {
  display: block;
}
// Style the indicator (dot/circle)
.cluey-radio__wrapper .cluey-radio__checkmark:after {
  top: $cluey-radio-offset;
  left: $cluey-radio-offset;
  width: $cluey-radio-inner-dimension;
  height: $cluey-radio-inner-dimension;
  border-radius: 50%;
  background: $blue_shade_05;
}

@include media-breakpoint-up(lg) {
  .contact-form__content {
    overflow: hidden;
    border-top: solid 8px $blaze-orange;
    border-radius: $border-radius-xl;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  }
}
