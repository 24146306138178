.summary {
  &-icon {
    height: 188px;
    width: 188px;
    border: $border-width-double solid $border-color;
    border-radius: 50%;
    @include media-breakpoint-down(md) {
      width: 21%;
    }
    margin-left: -5px;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  & .summary-card {
    width: 82%;
    max-width: 904px;
    @include media-breakpoint-down(sm) {
      width: 91%;
    }
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }

  & .summary-body {
    border-radius: 4px;

    @include media-breakpoint-down(xs) {
      border-radius: 0;
    }
  }

  & .summary-title {
    font-size: $font-size-largest;

    @include media-breakpoint-down(xs) {
      font-size: $font-size-large;
    }
  }

  & .summary-text {
    font-size: $font-size-small;
    line-height: 2;
    b {
      text-decoration: underline;
    }
  }
}

//tooltip
#pageAccess {
  color: theme-color('primary');
  font-size: $font-size-small-extra;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
  &::after {
    content: '   ';
    white-space: pre-wrap;
    display: inline-block;
  }

  &-tooltip {
    width: 300px;
    height: 112px;
    font-size: $font-size-small;
    text-align: left;

    div {
      width: 300px;
      height: 67px;
      margin: 0;
      padding: 18px 40px 21px 30px;
      position: relative;
    }
  }
}
