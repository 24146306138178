//card
.card-layout {
  box-shadow: $shadow-light;
  .test-simulation {
    a {
      text-decoration: underline;
    }
    i {
      margin-top: -4px;
    }
  }

  @include media-breakpoint-down(xs) {
    // width: 100%;
    border: none;
    border-radius: 0 !important;
  }
}

.card-body {
  border-radius: 0 0 4px 4px;
  @include media-breakpoint-down(xs) {
    border: 0;
  }
}
.card-headerWithMsg {
  font-family: $font-family-soft;
  font-size: $font-size-medium;
  letter-spacing: 0.2px;
  text-align: center;
  height: 40px;
  padding-top: 8px;
  z-index: 2;
  background-color: theme-color('third');
  color: $white;
  @include media-breakpoint-down(xs) {
    border-radius: 0 !important;
    border: 0;
  }
}

.card-text {
  font-size: $font-size-small;
  color: gray('300');
  line-height: 1.8;
  &-legend {
    font-size: $font-size-smallest;
  }
  b {
    font-family: $font-family-soft;
  }
}

.card-btn {
  font-family: $font-family-soft;
  font-size: $font-size-smallest;
  letter-spacing: 1.1px;
  line-height: 1;
  width: 163.8px;
}

.card-subTitle {
  font-family: $font-family-soft;
  font-size: $font-size-small-extra;
  line-height: 1.71;
}

.card-title {
  font-family: $font-family-soft;
  font-size: $font-size-large;
  color: gray('300');
  line-height: 1.6;
}

//activity items, modal
.custom-modal {
  width: 60%;
  max-width: 860px;
  margin: 150px auto;

  @include media-breakpoint-down(sm) {
    width: 70%;
    margin: 70px auto;
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}

.emoji-modal {
  cursor: pointer;
  color: theme-color('primary');
  background: transparent;
  border: none;
  font-size: $font-size-smaller;
  padding: 0;
  letter-spacing: normal;
}

.emoji-item {
  width: 48px;
  height: 48px;
}

.modal-title {
  font-size: $font-size-largest;
  color: gray('300');
  line-height: 1;
  margin-bottom: -10px;
}

.modal-backToPage {
  font-size: $font-size-small;
  color: $primary;
  box-shadow: $shadow-light;
  height: 64px;
  i {
    font-size: $font-size-large;
  }
}

.modal-btn {
  font-size: $font-size-medium;
  line-height: 1.17;
  text-align: center;
}

.icon {
  font-size: $font-size-large;
}

.circle {
  width: 38px;
  height: 38px;
  border-radius: 100%;
  .icon {
    font-size: $font-size-larger;
    text-align: center;
    position: absolute;
    margin-top: 5px;
    margin-left: 9px;

    @include media-breakpoint-down(sm) {
      margin-top: 5px;
      margin-left: 9.5px;
    }
    //todo to be designed
    // @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
    //   font-size: 10px;
    //   margin-top: 5px;
    //   margin-left: 5px;
    // }
  }
}

.feedback-box {
  width: 256px;
  height: 80px;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #d7d7e6;
  @include media-breakpoint-down(md) {
    width: 206px;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  .text-label {
    font-family: $font-family-soft;
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.3px;
  }
}

.video-container {
  overflow: hidden;
  // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
  padding-top: 56.25%;
  position: relative;
  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
