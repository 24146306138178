.hub {
  .auth {
    &-social {
      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.1);
        transition: all 0.25s;

        &:hover {
          box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.5);
          transform: scale3d(1.1, 1.1, 1.1);
        }
      }
    }

    input::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      pointer-events: none;
      position: absolute;
      right: 0;
    }
  }
}

@include media-breakpoint-up(lg) {
  .hub {
    .auth {
      &__left {
        .icon-wrap {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 312px;
          height: 312px;
          border-radius: 50%;
          &--salomie {
            background: linear-gradient(209deg, $pale-grey 37%, $salomie 86%);
          }

          &--sail {
            background: linear-gradient(209deg, $pale-grey 37%, $sail 86%);
          }

          img {
            position: absolute;
            top: 0;
            right: 0;

            &.icon-mail {
              width: 224px;
              height: auto;
            }
          }
        }
      }

      &__right {
        .content {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .hub {
    .auth {
      &__left {
        .icon-wrap {
          width: 400px;
          height: 400px;

          img {
            &.icon-mail {
              width: 288px;
              height: auto;
            }
          }
        }
      }

      &__right {
        padding-left: 96px !important;
        padding-right: 96px !important;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .hub {
    .auth {
      &__right {
        padding-left: 8% !important;
        padding-right: 8% !important;

        .container {
          max-width: 500px;
        }
      }
    }
  }
}
