.hub {
  .enrolment {
    .detail {
      .label-group {
        background-color: $white-ice;
        color: $blue-lagoon;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .actions {
      .action {
        position: relative;
        padding-left: 52px;
        padding-right: 20px;
        border-bottom: solid 1px $hawkes-blue;
        border-radius: 0;
        transition: color 0.5s linear;
        color: $denim;

        &:hover,
        &:active {
          color: darken($denim, 10%);
        }

        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        &:before {
          left: 0;
          width: 40px;
          height: 40px;
          border-radius: 14px;
          transition: background-size 0.1s ease-in-out;
          background-size: 50% 50%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        &:after {
          right: 0;
          width: 20px;
          height: 20px;
          background-position: center center;
          background-repeat: no-repeat;
        }

        &--view-activity {
          &:before {
            background-image: url(../../assets/images/icon--light-blue-activity.svg);
          }

          &:after {
            background-image: url(../../assets/images/icon-external-link.svg);
          }
        }

        &--update-schedule {
          &:before {
            background-image: url(../../assets/images/icon--light-blue-calendar.svg);
          }
        }

        &--feedback {
          &:before {
            background-image: url(../../assets/images/icon--light-blue-message-circle.svg);
          }
        }

        &--change-enrol {
          &:before {
            background-image: url(../../assets/images/icon--light-blue-clipboard.svg);
          }
        }

        &--update-schedule,
        &--feedback,
        &--change-enrol {
          &:after {
            background-image: url(../../assets/images/icon-arrow-right-primary.svg);
          }
        }

        &-intro {
          color: $ebony-clay;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .hub {
    .enrolment {
      .detail {
        padding-left: 80px !important;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 16px;
          width: 48px;
          height: 48px;
          border-radius: 4px;
          background-color: $white;
          background-image: url(../../assets/images/icon-profile.svg);
          background-position: center center;
          background-repeat: no-repeat;
        }

        .label-group {
          position: unset;
        }
      }

      .actions {
        .action {
          padding-left: 64px;

          &:hover,
          &:active {
            &:before {
              background-size: 75% 75%;
            }
          }

          &:after {
            display: none;
          }
        }
      }
    }
  }
}
